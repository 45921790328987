<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header backTo="stripe-transactions" title="Stripe Transaction"></page-header>
    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div v-if="transactionData" class="flex justify-between bg-white p-5 rounded shadow-md">
        <div>
          <h3
            class="text-3xl mb-2"
            v-if="transactionData.stripe_event == 'checkout.session.completed'"
          >Website Checkout for £{{ (transactionData.stripe_data.data.object.amount_total / 100).toFixed(2) }}</h3>
          <h3
            class="text-3xl mb-2"
            v-if="transactionData.stripe_event == 'payment_intent.succeeded'"
          >Manual Payment for £{{ (transactionData.stripe_data.data.object.amount / 100).toFixed(2) }}</h3>

          <h4
            class="text-xl"
            v-if="transactionData.xero_invoice_number"
          >Xero Invoice No: {{ transactionData.xero_invoice_number }} (processed at {{ $moment(transactionData.xero_processed_at).format('DD/MM/YYYY hh:mm') }})</h4>
          <div class="flex mt-4">
            <a
              target="_blank"
              v-if="transactionData.xero_processed && transactionData.xero_invoice_number"
              :href="`https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=${transactionData.xero_invoice_id}`"
              class="my-1 mr-2 flex items-center bg-gray-200 border hover:bg-gray-300 border-gray-400 hover:border-gray-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6M15 3h6v6M10 14L21 3" />
              </svg>
              <span>View Xero Invoice</span>
            </a>
            <a
              v-if="stripeInvoiceLink"
              target="_blank"
              :href="stripeInvoiceLink"
              class="my-1 flex items-center bg-gray-200 border hover:bg-gray-300 border-gray-400 hover:border-gray-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6M15 3h6v6M10 14L21 3" />
              </svg>
              <span>View Stripe Payment</span>
            </a>
          </div>
        </div>
      </div>
      <div class="w-full bg-white p-5 rounded shadow-md mt-6">
        <h2 class="text-xl mb-4">History</h2>
        <div class="-my-2">
          <h4 v-if="emptyHistory">No history available for this transaction</h4>

          <template v-for="(transaction, t) in transactionHistory">
            <div class="my-2 bg-gray-100 p-3 rounded-lg flex items-center" :key="t">
              <div class v-if="transaction.severity == 'Info'">
                <svg
                  class="stroke-current text-blue-600 h-8 w-8 mr-4"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M12 16v-4M12 8h.01" />
                </svg>
              </div>
              <div class v-else-if="transaction.severity == 'Warning'">
                <svg
                  class="stroke-current text-orange-600 h-8 w-8 mr-4"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0zM12 9v4M12 17h.01"
                  />
                </svg>
              </div>
              <div class v-else-if="transaction.severity == 'Error'">
                <svg
                  class="stroke-current text-red-600 h-8 w-8 mr-4"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M12 8v4M12 16h.01" />
                </svg>
              </div>
              <div class="flex flex-col">
                <span
                  class="text-sm text-gray-600 leading-none"
                >{{ $moment(new Date(transaction.logged_at)).format("LTS D/M/YYYY") }}</span>
                <p>{{transaction.message}}</p>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="bg-white p-5 rounded shadow-md mt-6 max-w-full">
        <h2 class="text-xl mb-4">Transaction Data</h2>
        <pre class="overflow-x-scroll min-w-0" v-if="transactionData">{{ transactionData }}</pre>
      </div>
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Notification = () => import("@/components/Notification.vue");

export default {
  name: "view-stripe-transaction",
  props: {
    transactionId: String,
  },
  components: {
    PageHeader,
    Notification,
  },
  data() {
    return {
      transactionData: null,
      transactionHistory: [],
      emptyHistory: false,
      viewState: "Idle",
      error: null,
    };
  },
  computed: {
    stripeInvoiceLink: function () {
      if (this.transactionData) {
        let paymentIntent;
        switch (this.transactionData.stripe_event) {
          case "checkout.session.completed":
            paymentIntent = this.transactionData.stripe_data.data.object
              .payment_intent;
            return `https://dashboard.stripe.com/payments/${paymentIntent}`;
            break;
          case "payment_intent.succeeded":
            paymentIntent = this.transactionData.stripe_data.data.object.id;
            return `https://dashboard.stripe.com/payments/${paymentIntent}`;
            break;
        }
      } else {
        return null;
      }
    },
  },
  async mounted() {
    this.viewState = "Loading";

    try {
      this.transactionData = await this.StripeTransactionService.getTransactionData(
        this.transactionId
      );

      this.transactionHistory = await this.StripeTransactionService.getTransactionHistory(
        this.transactionId
      );

      this.emptyHistory = this.transactionHistory.length == 0;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
